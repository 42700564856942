<template lang="pug">
el-autocomplete(
  v-model="search"
  @select="add"
  :fetch-suggestions="searchProduct"
  prefix-icon="el-icon-edit"
  suffix-icon="el-icon-search"
  placeholder="Stok kodu veya ürün adını giriniz"
  value-key="name"
  clearable
  style="width: 100%"
  :debounce="100"
  autocomplete="on"
  placement="bottom-end"
  ref="search"
  autofocus
  hide-loading
)
  el-button(
    slot="prepend"
    icon="el-icon-notebook-2"
    v-if="showBranch"
  ) {{ branch || $store.state.branch.name }}

  template(v-slot="{ item }")
    span.text-size-small.mr-15.text-muted {{ item.id }}
    span.text-bold {{ item.name }}

    span(style="float: right" :class="{ 'text-success': item.available >= 10, 'text-danger': item.available < 10 }")
      span.text-size-small.text-success.pr-20 {{ item.sell }} {{ item.sell_currency }}
      i.el-icon-warning.pr-5(v-if="item.available < 10")
      | {{ item.available }} {{ item.unit.name }}
</template>

<script>
export default {
  name: "ProductSelector",
  props: {
    warehouse: {
      required: false,
      type: Number,
      default: () => null
    },
    showBranch: {
      required: false,
      type: Boolean,
      default: () => false
    },
    branch: {
      required: false,
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      search: null,
    }
  },
  methods: {
    async searchProduct(keyword, callback) {
      if (keyword && keyword.length > 0) {
        let {data} = await window.axios.post(
            '/products/search',
            {keyword, warehouse: this.warehouse}
        )
        if (data.length > 0) {

          // try to find item in a list via id, add it to the cart
          let item = data.find((i) => i.id === Number(keyword))
          if (item != null) {
            this.add(data[0])
            return callback([])
          }

          return callback(data)
        }
      }

      return callback([])
    },
    add(product) {
      this.$emit('add', product)
      this.search = null
    }
  }
}
</script>

<style scoped>

</style>
