<template lang="pug">
div
  el-form.ml-10(label-position="top")
    el-row(:gutter="10")
      el-col(:span="5")
        el-form-item(label="Tedarikçi seçiniz")
          el-select(
            v-model="form.supplier_id"
            filterable
            clearable
            @change="fetchProducts"
            style="width: 100%"
          )
            el-option(
              v-for="s in $store.state.suppliers"
              :key="s.id"
              :label="s.name"
              :value="s.id"
            )
      el-col(:span="5")
        el-form-item(label="Ürün seçiniz")
          el-select(
            :value="null"
            filterable
            clearable
            @change="addProduct"
            style="width: 100%"
          )
            el-option(
              v-for="p in products"
              :key="p.id"
              :label="`${p.id} - ${p.name}`"
              :value="p"
            )
      el-col(:span="5")
        el-form-item(label="Şube/Depo")
          el-select(
            v-model="form.warehouse_id"
            filterable
            clearable
            style="width: 100%"
          )
            el-option-group(label="Şubeler")
              el-option(
                v-for="s in $store.state.branches"
                :key="s.id"
                :label="s.name"
                :value="s.id"
              )
            el-option-group(label="Depolar")
              el-option(
                v-for="s in $store.state.warehouses"
                :key="s.id"
                :label="s.name"
                :value="s.id"
              )
      el-col(:span="5")
        el-form-item(label="İşlem tarihi")
          el-date-picker(
            v-model="form.invoiced_at"
            type="date"
            format="dd.MM.yyyy"
            value-format="yyyy-MM-dd"
            :picker-options="{ firstDayOfWeek: 1 }"
            clearable
            placeholder="İşlem tarihi"
            style="width: 100%"
          )
      el-col(:span="4")
        el-form-item(label="Para birimi")
          el-select(v-model="form.currency" filterable clearable style="width: 100%")
            el-option(
              v-for="c in $store.state.currencies"
              :value="c"
              :key="c"
              :label="c"
            )
  table.table.table-xss.table-bordered.table-condensed(v-if="form.products.length > 0")
    thead
      th(style="width: 120px") Barkod
      th Ürün
      th(style="width: 150px") Miktar
      th(style="width: 150px") Fiyat
      th(style="width: 150px") KDV
      th(style="width: 150px") Toplam
    tbody
      tr(v-for="p in form.products")
        td: center.text-primary.text-bold {{ p.id }}
        td
          span.pull-left.mr-10(style="text-align: left!important")
            span.pl-10 {{ p.name }}
            small.text-muted &nbsp;/&nbsp;{{ p.available }} stokta
        td
          el-input(type="number" v-model.number="p.qty")
            span.mt-5(slot="suffix") {{ p.unit.name }}
        td.pr-10
          el-input(type="number" v-model.number="p.buy")
            span.mt-5(slot="suffix") {{ form.currency }}
        td.pr-10
          el-input(type="number" v-model.number="p.tax")
            span.mt-5(slot="suffix") %
        td
          el-input(type="number" :value="Number(((p.qty || 0) * (p.buy || 0)).toFixed(2))" readonly)
            span.mt-5(slot="suffix") {{ form.currency }}
    tfoot
      tr
        td(colspan="5")
          strong.pull-right.mr-10.text-success KDV
        td
          el-input(type="number" :value="tax" readonly)
            span(slot="suffix") {{ form.currency }}
      tr
        td(colspan="5")
          strong.pull-right.mr-10.text-success TOPLAM
        td
          el-input(type="number" :value="total" readonly)
            span(slot="suffix") {{ form.currency }}
  center
    el-button(
      type="primary"
      icon="el-icon-check"
      @click="save"
      :loading="saving"
      :disabled="!saveable"
    ) Kaydet
  el-alert(center type="warning" show-icon title="Tedarikçi seçiniz" v-if="form.supplier_id === null")
  el-alert(center type="warning" show-icon title="Tedarikçi ürünleri tanımlanmamış" v-if="form.supplier_id !== null && form.products.length === 0")
</template>
<script type="text/javascript">
import Product from '@/models/Product'
import collect from 'collect.js'

export default {
  name: 'BulkStockForm',
  data() {
    return {
      saving: false,
      products: [],
      form: {
        supplier_id: null,
        warehouse_id: null,
        invoiced_at: window.dayjs().format('YYYY-MM-DD'),
        paid_at: window.dayjs().format('YYYY-MM-DD'),
        currency: 'TRY',
        notes: null,
        products: [],
      }
    }
  },
  methods: {
    fetchProducts() {
      this.$emit('change-supplier', this.form.supplier_id)
      this.$emit('change-warehouse', this.form.warehouse_id)
      if (this.form.supplier_id) {
        Product.where('supplier_id', this.form.supplier_id)
          .get()
          .then((res) => {
            this.products = res.data
          })
      }
    },
    addProduct(product) {
      this.form.products.push(product)
    },
    async save() {
      try {
        this.saving = true
        await window.axios.post('/invoices', this.form)
        this.$emit('update', true)
        this.saving = false
        this.form.products = []
        this.$message.success('Ürünler kaydedildi')
      } catch (e) {
        this.saving = false
        this.$message.error('Kaydedilemedi')
      }
    }
  },
  computed: {
    saveable() {
      return (
        this.form.supplier_id !== null
        && this.form.warehouse_id
        && this.form.invoiced_at
        && this.products.length > 0
      )
    },
    total() {
      return collect(this.form.products).sum((p) => Number(((p.qty || 0) * (p.buy || 0)).toFixed(2)))
    },
    tax() {
      return collect(this.form.products).sum((p) => Number(((p.qty || 0) * (p.buy || 0)).toFixed(2)) * p.tax / 100)
    }
  }
}
</script>
