<template lang="pug">
  div
    el-alert(
      title="Bu bölümü görüntüleme yetkiniz yok"
      v-if="!can('products.list')"
      :closable="false"
      type="error"
      show-icon
      center
    )
    el-card(body-style="padding:0" v-if="can('products.list')")
      div(slot="header")
        center
          el-form(inline)
            el-form-item
              el-button(type="primary" @click="create" icon="el-icon-plus" :disabled="!can('products.create')") Oluştur
              el-button(type="info" @click="invoiceModal = true" icon="el-icon-shopping-cart-full") Mal alımı
              el-button(type="info" @click="transferModal = true" icon="el-icon-truck") Transfer
            el-form-item
              el-button-group
                el-button(type="info" plain @click="print(null)" icon="el-icon-printer" :disabled="selected.length === 0") Yazdır
                el-button(type="info" plain @click="barcodeOptions = true" icon="el-icon-setting" :disabled="selected.length === 0")
            el-form-item(label="Şube / Depo")
              el-select(
                v-model="filters.warehouses"
                filterable
                clearable
                multiple
                style="width: 100%"
                :disabled="product.hasOwnProperty('id')"
              )
                el-option-group(label="Şubeler")
                  el-option(
                    v-for="s in $store.state.branches"
                    :key="s.id"
                    :label="s.name"
                    :value="s.id"
                  )
                el-option-group(label="Depolar")
                  el-option(
                    v-for="s in $store.state.warehouses"
                    :key="s.id"
                    :label="s.name"
                    :value="s.id"
                  )

            el-form-item(label="Tedarikçi")
              el-select(v-model="filters.suppliers" filterable multiple clearable default-first-option)
                el-option(v-for="s in $store.state.suppliers" :key="s.id" :value="s.id" :label="s.name")

            el-form-item(label="Kategori")
              el-select(v-model="filters.categories" filterable multiple clearable default-first-option)
                el-option(v-for="c in $store.state.categories" :key="c.id" :value="c.id" :label="c.name")

            el-form-item
              el-button-group
                el-button(icon="el-icon-tickets" :loading="fetching" @click="fetch(false)" type="info" plain) Getir
                el-button(@click="fetch(true)" icon="el-icon-download" type="info" plain :loading="downloading") Excel
            el-form-item
              el-button(
                @click="bulkRecalc"
                icon="el-icon-refresh"
                type="info"
                :loading="recalculating"
              ) Yeniden hesapla

        el-dialog(title="BARKOD AYARLARI" :visible.sync="barcodeOptions" width="300px" top="20px")
          el-form(label-position="left" label-width="150px")
            el-form-item(label="Barkod width" v-show="true")
              el-input(type="number" v-model.number="printOptions.width" style="width: 100px" :step="0.1")
            el-form-item(label="Barkod height" v-show="true")
              el-input(type="number" v-model.number="printOptions.height" style="width: 100px" :step="1")
            el-form-item(label="Barkod font size" v-show="true")
              el-input(type="number" v-model.number="printOptions.fontSize" style="width: 100px" :step="1")
            el-form-item(label="Barkod margin")
              el-input(type="number" v-model.number="printOptions.margin" style="width: 100px" :step="0.1")
            el-form-item(label="Barkod text margin")
              el-input(type="number" v-model.number="printOptions.textMargin" style="width: 100px" :step="0.1")
            el-form-item(label="Product line height")
              el-input(type="number" v-model.number="printOptions.productLineHeight" style="width: 100px" :step="0.1")
            el-form-item(label="Product font size")
              el-input(type="number" v-model.number="printOptions.productFontSize" style="width: 100px" :step="0.1")
            el-form-item(label="Price line height")
              el-input(type="number" v-model.number="printOptions.priceLineHeight" style="width: 100px" :step="0.1")
            el-form-item(label="Price font size")
              el-input(type="number" v-model.number="printOptions.priceFontSize" style="width: 100px" :step="0.1")

      el-input(
        placeholder="Ürün adı, tedarikçi, kategori ismi girerek arama yapınız"
        v-model="search"
        @input="fetch(false)"
        clearable
        v-if="can('products.list')"
      )
        i.el-input__icon.el-icon-search(slot="prefix")

      el-table.border-top(
        :data="products.data"
        v-loading="fetching"
        @row-dblclick="edit"
        size="small"
        @selection-change="selected = $event"
        :height="$store.state.windowHeight-228"
        v-if="can('products.list')"
      )
        el-table-column(type="selection" width="25px" align="center")
        el-table-column(type="index" width="26px" class-name="text-small text-muted" align="right" label="#")
        el-table-column(prop="id" label="Barkod" sortable width="110px" align="center" class-name="text-bold")
        el-table-column(
          prop="oldid"
          label="Eski barkod"
          sortable
          width="110px"
          align="center"
          class-name="text-bold"
          v-if="hasFeature('old.stock.codes')"
        )
          template(v-slot="props"): span.text-danger {{ props.row.oldid }}
        el-table-column(prop="name" label="Ürün" sortable class-name="text-bold" show-overflow-tooltip min-width="180px")
        el-table-column(prop="supplier.name" label="Tedarikçi" sortable show-overflow-tooltip width="130px")
        el-table-column(prop="category.name" label="Kategori" sortable show-overflow-tooltip min-width="120px")
        el-table-column(prop="buy" label="Alış fiyatı" sortable align="right" width="100px")
          template(v-slot="props") {{ `${props.row.buy} ${props.row.buy_currency}` }}
        el-table-column(prop="sell" label="Satış fiyatı" sortable align="right" width="100px")
          template(v-slot="props") {{ `${props.row.sell} ${props.row.sell_currency}` }}
        el-table-column(label="MİKTARLAR" align="center")
          el-table-column(prop="total" label="Alınan" sortable align="right" class-name="text-primary" width="100px")
            template(v-slot="props")
              strong {{ Number(props.row.total) }} {{ props.row.unit.name }}
          el-table-column(prop="available" label="Kalan" sortable align="right" class-name="text-success" width="100px")
            template(v-slot="props")
              strong {{ Number(props.row.available) }} {{ props.row.unit.name }}
          el-table-column(prop="sold" label="Satılan" sortable align="right" class-name="text-danger" width="100px")
            template(v-slot="props")
              strong {{ Number(props.row.sold) }} {{ props.row.unit.name }}
          el-table-column(prop="returned" label="İade" sortable align="right" class-name="text-danger" width="100px")
            template(v-slot="props")
              strong {{ Number(props.row.returned) }} {{ props.row.unit.name }}
        el-table-column(prop="id" label="Alımlar" align="center" width="165px" fixed="right")
          template(v-slot="props")
            el-button-group
              el-button.micro(
                icon="el-icon-tickets"
                @click="showStocks(props.row)"
                :loading="fetchingStocks === props.row.id"
              )
                span Alımlar
              el-button.micro(
                icon="el-icon-plus"
                @click="createStock(props.row)"
              )
              el-button.micro(
                type="warning"
                plain
                @click="returnStock(props.row)"
                icon="el-icon-refresh-left"
              )
        el-table-column(prop="id" label="İşlem" align="center" fixed="right" width="120px")
          template(v-slot="props")
            el-button-group
              el-button.micro(icon="el-icon-edit" @click="edit(props.row)" :disabled="!can('products.edit')")
              el-button.micro(icon="el-icon-printer" @click="print(props.row)")
              el-button.micro.text-danger(icon="el-icon-delete" @click="remove(props.row.id)" :disabled="!can('products.remove')")
        el-table-column(prop="created_at" label="Ekleme tarihi" align="center" sortable width="140px")
          template(v-slot="props") {{ props.row.created_at|dayjs('DD.MM.YYYY / HH:mm') }}
      el-pagination.mt-5.mb-5.ml-5.text-center(
        hide-on-single-page
        background
        layout="prev, pager, next"
        :total="products.meta.total"
        :page-size="products.meta.per_page"
        :current-page.sync="page"
        @current-change="fetch(false)"
      )

    el-drawer(:visible.sync="modal" size="700px" append-to-body :wrapperClosable="false")
      span.ml-20.text-bold(slot="title") {{ product.name || 'Yeni ürün' }}
      el-form#stock.pt-10.pl-20.pr-20(label-position="top")
        el-row(:gutter="20")
          el-col(:span="24")
            el-alert.mt-10.mb-10(title="Yeni tanımlanacak ürünün miktarı ve teslim alan şube/depo bu ekrandan girilmektedir. Tanımlandıktan sonra ürün sayısını arttırmak ve azaltmak için Mal alımı ekranını kullanınız" type="success" center show-icon :closable="false" effect="dark")
          el-col(:span="24")
            el-form-item(label="Ürün adı")
              el-input(v-model="product.name" :maxlength="100" show-word-limit)
          el-col(:span="12")
            el-form-item(label="Şube/Depo")
              el-select(
                v-model="product.warehouse_id"
                filterable
                clearable
                style="width: 100%"
                :disabled="product.hasOwnProperty('id')"
              )
                el-option-group(label="Şubeler")
                  el-option(
                    v-for="s in $store.state.branches"
                    :key="s.id"
                    :label="s.name"
                    :value="s.id"
                  )
                el-option-group(label="Depolar")
                  el-option(
                    v-for="s in $store.state.warehouses"
                    :key="s.id"
                    :label="s.name"
                    :value="s.id"
                  )
          el-col(:span="12")
            el-form-item(label="Miktar")
              el-input(v-model.number="product.available" type="number" :disabled="product.hasOwnProperty('id')")
                el-select(v-model="product.unit_id" slot="append" filterable)
                  el-option(v-for="u in $store.state.types.filter(t => t.category === 'unit')" :key="u.id" :label="u.name" :value="u.id")
          el-col(:sm="12")
            el-form-item(label="Tedarikçi")
              el-select(v-model="product.supplier_id" style="width:100%" filterable default-first-option)
                el-option(v-for="s in $store.state.suppliers" :key="s.id" :label="s.name" :value="s.id")
          el-col(:sm="12")
            el-form-item(label="Ürün kategorisi")
              el-select(v-model="product.category_id" style="width:100%" filterable default-first-option)
                el-option(v-for="c in $store.state.categories" :key="c.id" :label="c.name" :value="c.id")
          el-col(:span="12")
            el-form-item(label="Alış fiyatı")
              el-input(v-model="product.buy" type="number" required)
                el-select(v-model="product.buy_currency" slot="append")
                  el-option(v-for="c in $store.state.currencies" :key="c" :label="c" :value="c")
          el-col(:span="12")
            el-form-item(label="Satış fiyatı")
              el-input(v-model="product.sell" type="number" required)
                el-select(v-model="product.sell_currency" slot="append")
                  el-option(v-for="c in $store.state.currencies" :key="c" :label="c" :value="c")
          el-col(:span="24" v-if="hasFeature('old.stock.codes')")
            el-form-item(label="Eski stok kodu")
              el-input(v-model="product.oldid")

        el-button.mt-10(
          type="primary"
          icon="el-icon-check"
          @click="save"
          :loading="saving"
          :disabled="!savable"
        ) Kaydet
        el-button.mt-10.text-danger(
          type="text"
          icon="el-icon-delete"
          @click="remove(product.id)"
          :loading="deleting"
          v-if="product.id"
        ) Sil

    el-dialog(
      :visible.sync="stocksModal"
      fullscreen
      append-to-body
      v-if="stocksModal && selectedProduct"
    )
      center.text-bold(slot="title") {{ selectedProduct.name }} | {{ selectedProduct.id }}
      grid.pb-20(
        :clickable="false"
        :column-num="4"
        direction="horizontal"
        icon-size="18px"
        :gutter="5"
      )
        grid-item(icon="todo-list-o")
          span.pl-10(slot="text")
            span Alınan &nbsp;
            tag.ml-5(type="primary") {{ selectedProduct.total }}
        grid-item(icon="todo-list-o")
          span.pl-10(slot="text")
            span Kalan &nbsp;
            tag.ml-5(type="success") {{ selectedProduct.available }}
        grid-item(icon="todo-list-o")
          span.pl-10(slot="text")
            span Satılan &nbsp;
            tag.ml-5(type="success") {{ selectedProduct.sold }}
        grid-item(icon="todo-list-o")
          span.pl-10(slot="text")
            span İade &nbsp;
            tag.ml-5(type="danger") {{ selectedProduct.returned }}
      el-row(:gutter="20")
        el-col(:span="8")
          el-tabs(stretch)
            el-tab-pane(label="STOK DAĞILIMI")
          stock-totals(:records="stockTotals")
        el-col(:span="16")
          el-tabs(stretch)
            el-tab-pane(label="STOK HAREKETLERİ")
            //-el-tab-pane(label="TRANSFERLER")
            //-el-tab-pane(label="İADELER")
          el-table(
            :data="stocks"
            @row-dblclick="editStock"
            default-expand-all
            size="mini"
            border
            stripe
            v-loading="fetchingStocks === selectedProduct.id"
          )
            el-table-column(type="expand")
              template(v-slot="props")
                tag(
                  v-if="props.row.notes"
                  style="margin-bottom: 10px;"
                  plain
                  type="warning"
                ) Not: {{ props.row.notes }}

            el-table-column(prop="qty" label="Miktar" sortable width="100px" align="right")
              template(v-slot="props")
                span(:class="{'text-danger': props.row.qty < 0}") {{ props.row.qty }}
                span.pl-10.text-muted.text-size-mini(v-if="selectedProduct && selectedProduct.unit") {{ selectedProduct.unit.name }}
            el-table-column(prop="price" label="Alış fiyatı" sortable width="100px" align="right")
              template(v-slot="props")
                span(:class="{'text-danger': props.row.price < 0}") {{ props.row.price }}
            el-table-column(prop="amount" label="Ödenen" sortable align="right" width="100px")
              template(v-slot="props")
                span(:class="{'text-danger': props.row.amount < 0}") {{ props.row.amount }}
            el-table-column(prop="currency" label="$$" align="center" width="60px")
            el-table-column(prop="actionLabel" label="işlem" align="center" width="100px")
              template(v-slot="props"): tag(:type="props.row.actionColor") {{ props.row.actionLabel }}
            el-table-column(prop="warehouse.name" label="Şube/Depo" sortable)
              template(v-slot="props")
                span(v-if="['buy', 'return'].includes(props.row.action)") {{ props.row.warehouse.name }}
                tag(v-if="props.row.action === 'shipped'" :type="props.row.actionColor")
                  span {{ props.row.transfer.sender.name }} &rarr; {{ props.row.transfer.receiver.name }}
                tag(v-if="props.row.action === 'received'" :type="props.row.actionColor")
                  span {{ props.row.transfer.receiver.name }} &larr; {{ props.row.transfer.sender.name }}
            el-table-column(prop="created_at" label="Ekleme tarihi" sortable width="140px" align="center")
              template(v-slot="props") {{ props.row.created_at|dayjs('DD.MM.YYYY / HH:mm') }}
            el-table-column(prop="id" label="İşlemler" width="100px" align="center")
              template(v-slot="props")
                el-button.micro(icon="el-icon-edit" @click="editStock(props.row)")
                el-popconfirm(
                  title="Kayıtı silmek istediğinizden emin misiniz?"
                  @confirm="removeStock(props.row)"
                )
                  el-button.micro.text-danger(
                    slot="reference"
                    icon="el-icon-delete"
                    :loading="removingStock === props.row.id"
                  )

    el-dialog(
      :visible.sync="invoiceModal"
      fullscreen
      append-to-body
      :wrapperClosable="false"
    )
      center.ml-20.text-bold(slot="title") TOPLU MAL ALIMI
      invoice-form(
        @update="fetchStocks;invoiceModal = false"
        @change-supplier="filters.suppliers = [$event];fetchStocks"
      )
    el-dialog(
      :visible.sync="stockModal"
      width="550px"
      top="20px"
      :close-on-click-modal="false"
      center
      v-if="stockModal && selectedProduct"
    )
      center.ml-20.text-bold(slot="title") {{ selectedProduct.name + ' | MAL ALIMI' }}
      center.mt-10
        el-tag.mr-5 Alınan: {{ selectedProduct.total }}
        el-tag.mr-5(type="info") Kalan: {{ selectedProduct.available }}
        el-tag.mr-5(type="success") Satılan: {{ selectedProduct.sold }}
        el-tag(type="danger") İade: {{ selectedProduct.returned }}
      el-form(label-position="top")
        el-form-item(label="Miktar")
          el-input(v-model.number="stock.qty" type="number")
            span(slot="append" v-if="selectedProduct && selectedProduct.unit") {{ selectedProduct.unit.name }}
        el-form-item(label="Alım fiyatı")
          el-input(v-model.number="stock.price" type="number")
            span.ml-10.mr-5(slot="suffix") {{ selectedProduct.buy_currency }}
        el-form-item(label="Şube/Depo")
          el-select(
            v-model="stock.warehouse_id"
            filterable
            clearable
            style="width: 100%"
          )
            el-option-group(label="Şubeler")
              el-option(
                v-for="s in $store.state.branches"
                :key="s.id"
                :label="s.name"
                :value="s.id"
              )
            el-option-group(label="Depolar")
              el-option(
                v-for="s in $store.state.warehouses"
                :key="s.id"
                :label="s.name"
                :value="s.id"
              )
        el-form-item(label="Not")
          el-input(v-model="stock.notes" type="textarea")
      div(slot="footer")
        el-button(type="primary" @click="saveStock" icon="el-icon-check" :loading="savingStock" :disabled="!stockSavable") KAYDET
        el-button(@click="stockModal = false" icon="el-icon-close") KAPAT
    el-dialog(
      :visible.sync="returnModal"
      width="550px"
      top="20px"
      :close-on-click-modal="false"
      center
      v-if="returnModal && selectedProduct"
    )
      center.ml-20.text-bold(slot="title") {{ selectedProduct.name + ' | MAL İADE' }}
      center.mt-10
        el-tag.mr-5 Alınan: {{ selectedProduct.total }}
        el-tag.mr-5(type="info") Kalan: {{ selectedProduct.available }}
        el-tag.mr-5(type="success") Satılan: {{ selectedProduct.sold }}
        el-tag(type="danger") İade: {{ selectedProduct.returned }}
      el-form(label-position="top")
        el-form-item(label="İade miktarı")
          el-input(v-model.number="stock.qty" type="number")
            span(slot="append" v-if="selectedProduct && selectedProduct.unit") {{ selectedProduct.unit.name }}
        el-form-item(label="Alım fiyatı")
          el-input(v-model.number="stock.price" type="number")
            span.ml-10.mr-5(slot="suffix") {{ selectedProduct.buy_currency }}
        el-form-item(label="Şube/Depo")
          el-select(
            v-model="stock.warehouse_id"
            filterable
            clearable
            style="width: 100%"
          )
            el-option-group(label="Şubeler")
              el-option(
                v-for="s in $store.state.branches"
                :key="s.id"
                :label="s.name"
                :value="s.id"
              )
            el-option-group(label="Depolar")
              el-option(
                v-for="s in $store.state.warehouses"
                :key="s.id"
                :label="s.name"
                :value="s.id"
              )
        el-form-item(label="Not")
          el-input(v-model="stock.notes" type="textarea")
      div(slot="footer")
        el-button(type="primary" @click="saveStock" icon="el-icon-check" :loading="savingStock" :disabled="!stockReturnable") KAYDET
        el-button(@click="returnModal = false" icon="el-icon-close") KAPAT

    div#printarea(ref="printarea")
      body(style="margin: 0; padding: 0")
        template(v-for="s in selected")
          span(:key="s.id" style="display: block; page-break-after: always!important")
            center(:style="`line-height: ${printOptions.productLineHeight}mm;font-family: monospace; font-size: ${printOptions.productFontSize}mm; font-weight: normal; overflow: hidden`")
              | {{ s.name.substring(0, 24) }}
            center(:style="`line-height: ${printOptions.priceLineHeight}mm;font-family: monospace; font-size: ${printOptions.priceFontSize}mm; font-weight: 700`")
              | Price: {{ parseFloat(s.sell).toFixed(2).replace('.00', '') }} {{ s.sell_currency }}
            center
              barcode(:value="s.id" :options="printOptions" tag="svg")
    el-dialog(
      :visible.sync="transferModal"
      center
      width="700"
    )
      center.ml-20.text-bold(slot="title") MAL TRANSFERİ
      transfer-form(@update="transferModal = false;fetch(false)")
</template>
<script>
import Product from '@/models/Product'
import ProductCategory from '@/models/ProductCategory'
import ProductStock from '@/models/ProductStock'
import Company from '@/models/Company'
import Type from '@/models/Type'
import { Printd } from 'printd'
import VueBarcode from '@chenfengyuan/vue-barcode'
import InvoiceForm from '@/components/InvoiceForm.vue'
import TransferForm from '@/components/TransferForm.vue'
import StockTotals from '@/components/StockTotals.vue'
import { Grid, GridItem, Tag } from 'vant'

export default {
  name: 'stock',
  components: {
    barcode: VueBarcode,
    InvoiceForm,
    TransferForm,
    StockTotals,
    Grid,
    GridItem,
    Tag
  },
  data(){
    return {
      product: new Product({
        name: '',
        warehouse_id: null,
        available: 0,
        unit_id: null,
        supplier_id: null,
        category_id: null,
        buy: 0,
        sell: 0,
        buy_currency: this.$store.state.currency,
        sell_currency: 'USD'
      }),
      modal: false,
      invoiceModal: false,
      transferModal: false,
      selectedProduct: { name: '' },
      saving: false,
      fetching: false,
      downloading: false,
      barcodeOptions: false,
      branches: [],
      warehouses: [],
      products: {
        data: [],
        meta: {
          total: 0,
          per_page: 20
        }
      },
      filters: {
        categories: [],
        suppliers: [],
        branches: [],
        warehouses: []
      },
      selected: [],
      search: '',
      page: 1,
      stocks: [],
      stockTotals: [],
      returnModal: false,
      stockModal: false,
      stocksModal: false,
      fetchingStocks: null,
      stock: new ProductStock({
        product_id: null,
        warehouse_id: null,
        invoice_id: null,
        action: 'buy',
        qty: 0,
        price: 0,
        currency: this.$store.state.currency,
        notes: null
      }),
      savingStock: false,
      removingStock: null,
      recalculating: false,
      printOptions: {
        displayValue: true,
        height: 20,
        width: 1.2,
        margin: 0,
        textMargin: 0,
        fontSize: 11,
        productLineHeight: 2.5,
        productFontSize: 2.5,
        priceLineHeight: 3,
        priceFontSize: 3
      }
    }
  },
  mounted(){
    if(this.can('products.list')) {
      this.fetch(false)
      Company.select('id', 'name')
        .where('type', 'supplier')
        .get()
        .then((data) => {
          this.$store.dispatch('fill', ['suppliers', data])
        })
      ProductCategory.select('id', 'name')
        .get()
        .then((data) => {
          this.$store.dispatch('fill', ['categories', data])
        })
    }
  },
  methods: {
    async fetch(excel) {
      if (!excel) {
        this.fetching = true
      } else {
        this.downloading = true
      }
      try {
        const data = await Product.whereIn('category_id', this.filters.categories)
          .whereIn('supplier_id', this.filters.suppliers)
          .params({
            search: this.search ? this.search.toLowerCase() : '',
            warehouses: this.filters.warehouses,
            branches: this.filters.branches,
            excel
          })
          .page(this.page)
          .limit(20)
          .get()

          if (!excel) {
            this.products = data
          } else {
            window.location.href = data[0].url;
          }
          this.downloading = false
          this.fetching = false
      } catch(e) {
        this.fetching = false
        this.downloading = false
        this.$message.error('Sunucu hatası: ' + e.message)
      }
    },
    create(){
      this.product = new Product({
        name: '',
        category_id: null,
        supplier_id: null,
        available: 0,
        unit_id: 'pcs',
        vat: 18,
        buy: 0,
        buy_currency: process.env.VUE_APP_DEFAULT_CURRENCY || 'TRY',
        sell: 0,
        sell_currency: process.env.VUE_APP_DEFAULT_CURRENCY || 'USD'
      })
      this.modal = true
    },
    async edit({ id }){
      if(this.can('products.edit')) {
        try{
          this.product = await Product.find(id)
          this.modal = true
        }catch(e){
          this.$message.error('Sunucu hatası: ' + e.message)
        }
      }
    },
    async save() {
      this.saving = true
      try {
        await this.product.save()
        this.saving = false
        this.modal = false
        await this.fetch(false)
      } catch(e) {
        this.saving = false
        this.$message.error('Ürün kaydedilemedi: ' + e.message)
      }
    },
    print(row) {
      if(row) {
        this.selected = [row];
      }
      this.$nextTick(() => {
        let d = new Printd()
        d.print(this.$refs.printarea)
      })
    },
    async remove(id) {
      await this.$confirm('Kayıt silinmesine onay veriniz', 'Uyarı', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Vazgeç',
        type: 'warning',
        center: true
      })
      try {
        let { data } = await window.axios.delete('/products/' + id)
        this.modal = false
        await this.fetch(false)
        if (data.deleted) {
          this.$message({
            type: 'success',
            message: 'Kayıt silinmiştir',
            center: true,
            closable: true
          })
        } else {
          this.$message.error('Kayıt silinemedi')
        }
      } catch(e) {
        this.$message({
          type: 'error',
          message: 'Kayıt üzerinde satış olduğundan dolayı silinemez',
          center: true,
          closable: false
        })
      }
    },
    async showStocks(product) {
      await this.fetchStocks(product)
      this.stocksModal = true
    },
    async fetchStocks(product) {
      console.log('fetched stocks')
      this.selectedProduct = await Product.find(product.id)
      try {
        this.fetchingStocks = product.id
        this.stocks = await this.selectedProduct.stocks().get()
        const { data } = await window.axios.get(`/products/${this.selectedProduct.id}/totals`)
        this.stockTotals = data
        this.fetchingStocks = null
        this.invoiceModal = false
      } catch (e) {
        this.stocksModal = false
        this.fetchingStocks = null
      }
    },
    async createStock(product) {
      this.selectedProduct = await Product.find(product.id)
      this.stock = new ProductStock({
        product_id: product.id,
        warehouse_id: null,
        invoice_id: null,
        action: 'buy',
        qty: 0,
        price: product.buy,
        currency: product.buy_currency,
        notes: null
      })
      this.stockModal = true
    },
    editStock(row) {
      this.stock = row
      this.stockModal = true
    },
    async saveStock() {
      try {
        this.savingStock = true
        await this.stock.save()
        await this.fetchStocks(this.selectedProduct)
        await this.fetch()
        this.savingStock = false
        this.stockModal = false
        this.returnModal = false
      } catch(e) {
        this.$message.error('Stok kaydedilemedi')
        this.savingStock = false
      }
    },
    async returnStock(product) {
      this.selectedProduct = await Product.find(product.id)
      this.stock = new ProductStock({
        product_id: product.id,
        warehouse_id: null,
        action: 'return',
        qty: 0,
        price: product.buy,
        currency: product.buy_currency,
        notes: null
      })
      this.returnModal = true
    },
    async removeStock(stock) {
      try {
        this.removingStock = stock.id
        await stock.delete()
        console.log('removed stocks')
        await this.fetchStocks(this.selectedProduct)
        await this.fetch()
        this.selectedProduct = await Product.find(product.id)
        this.removingStock = null
      } catch(e) {
        this.removingStock = null
      }
    },
    async bulkRecalc() {
      this.recalculating = true
      await window.axios.post('stock/recalc')
      this.recalculating = false
      this.fetch(false)
    }
  },
  computed: {
    stockSavable() {
      return this.stock.qty > 0 && this.stock.warehouse_id !== null
    },
    stockReturnable() {
      return this.stock.qty > 0 && this.stock.warehouse_id !== null
    },
    savable() {
      return (
        this.product.name.length > 0
        //&& this.product.warehouse_id !== null
        //&& this.product.available > 0
        && this.product.unit_id !== null
        && this.product.supplier_id !== null
        && this.product.category_id !== null
        && this.product.buy > 0
        && this.product.sell > 0
      )
    }
  },
  watch: {
    filters: {
      deep: true,
      handler: function(){
        this.page = 1
        this.fetch(false)
      }
    }
  }
}
</script>
<style>
#name, #price { line-height: 3.5mm }
.pb { display: block; page-break-after: always!important; }
#stock div.el-input-group__append div.el-input--suffix input.el-input__inner {
  width: 78px;
}
@media screen {
  #printarea{
    visibility: hidden;
    display: none;
  }
}
</style>
