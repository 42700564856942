<template lang="pug">
div
  div
    el-form(label-position="top")
      el-row(:gutter="10")
        el-col(:span="12")
          el-form-item(label="Çıkış yeri")
            el-select(
              v-model="form.sender_id"
              filterable
              style="width: 100%"
            )
              el-option-group(label="Şubeler")
                el-option(
                  v-for="s in $store.state.branches"
                  :key="s.id"
                  :label="s.name"
                  :value="s.id"
                  :disabled="form.receiver_id === s.id"
                )
              el-option-group(label="Depolar")
                el-option(
                  v-for="s in $store.state.warehouses"
                  :key="s.id"
                  :label="s.name"
                  :value="s.id"
                  :disabled="form.receiver_id === s.id"
                )
        el-col(:span="12")
          el-form-item(label="Varış yeri")
            el-select(
              v-model="form.receiver_id"
              filterable
              clearable
              style="width: 100%"
            )
              el-option-group(label="Şubeler")
                el-option(
                  v-for="s in $store.state.branches"
                  :key="s.id"
                  :label="s.name"
                  :value="s.id"
                  :disabled="form.sender_id === s.id"
                )
              el-option-group(label="Depolar")
                el-option(
                  v-for="s in $store.state.warehouses"
                  :key="s.id"
                  :label="s.name"
                  :value="s.id"
                  :disabled="form.sender_id === s.id"
                )
        el-col(:span="24")
          el-form-item
            product-selector(@add="add" :warehouse="form.sender_id")
  table.table.table-xss.table-hover.border-bottom.border-right.table-bordered.no-margin-bottom(v-if="form.stocks.length > 0")
    thead
      tr
        th(style="width: 32px;") #
        th.text-center(style="width: 90px;") Stok kodu
        th.text-right Ürün
        th.text-right(style="width: 130px;") Stok miktarı
        th.text-right(style="width: 130px;") Transfer miktarı
    tbody
      tr(v-for="(s, i) in form.stocks" :key="s.id")
        td.text-muted.text-left &nbsp;{{ i+1 }}
        td: center {{ s.id }}
        td: span.pr-5 {{ s.name }}
        td
          el-input(:value="s.available" type="number" readonly)
        td
          el-input(v-model="s.total" type="number" :max="s.available")
  el-form.mt-10(label-position="top")
    el-form-item(label="Not")
      el-input(type="textarea" v-model="form.note" placeholder="Not")
  div
    el-button(
      @click="save"
      type="primary"
      :disabled="!saveable"
      :loading="saving"
    ) KAYDET
</template>
<script>
import ProductSelector from '@/components/ProductSelector'
import StockTransfer from '@/models/StockTransfer'

export default {
  name: 'TransferForm',
  components: {
    ProductSelector
  },
  data() {
    return {
      saving: false,
      form: new StockTransfer({
        sender_id: null,
        receiver_id: null,
        status_id: null,
        note: null,
        stocks: []
      })
    }
  },
  methods: {
    add(product) {
      let found = this.form.stocks.find((item) => item.id === product.id)
      if (found) {
        found.total += 1
      } else {
        product.total = 1
        this.form.stocks.push(product)
      }
    },
    newTransfer() {
      this.form = new StockTransfer({
        sender_id: null,
        receiver_id: null,
        status_id: null,
        note: null,
        stocks: []
      })
    },
    async save() {
      try {
        this.saving = true
        await this.form.save()
        this.saving = false
        this.newTransfer()
        this.$emit('update')
        this.$message.success('TRANSFER KAYDEDİLDİ')
      } catch (e) {
        this.$message.error('TRANSFER KAYDEDİLEMEDİ')
      }
    }
  },
  computed: {
    saveable() {
      return (
        this.form.sender_id !== null
        && this.form.receiver_id !== null
        && this.form.stocks.length > 0
      )
    }
  }
}
</script>
