<template lang="pug">
el-table(:data="records" size="mini" border stripe show-summary)
  el-table-column(prop="warehouse.name" label="Şube/Depo" class-name="text-size-mini")
  el-table-column(prop="total" label="Alınan" sortable align="right" class-name="text-size-mini")
    template(v-slot="props")
      span(:class="{'text-danger': props.row.total < 0}") {{ props.row.total }}
  el-table-column(prop="available" label="Kalan" sortable align="right" class-name="text-size-mini")
    template(v-slot="props")
      span(:class="{'text-danger': props.row.available < 0}") {{ props.row.available }}
  el-table-column(prop="sold" label="Satılan" sortable align="right" class-name="text-size-mini text-success")
    template(v-slot="props")
      span(:class="{'text-danger': props.row.sold < 0}") {{ props.row.sold }}
  el-table-column(prop="returned" label="İade" sortable align="right" class-name="text-size-mini text-danger")
    template(v-slot="props")
      span(:class="{'text-danger': props.row.returned < 0}") {{ props.row.returned }}
</template>
<script>
export default {
  name: 'StockTotals',
  props: {
    records: {
      required: false,
      type: Array,
      default: () => []
    }
  }
}
</script>
